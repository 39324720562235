<template>
  <b-overlay
    :show="spinner"
    spinner-variant="primary"
  >
    <b-card>
      <b-row>
        <b-col
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="getCompras.length"
          />
        </b-col>
        <b-col
          lg="6"
          md="5"
          sm="5"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </b-col>
        <b-col
          md="4"
          sm="4"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <btnCrear
            texto="OC"
            modulo="compras"
            @processAdd="addCompra"
          />
        </b-col>

        <b-col cols="12">
          <b-table
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="getCompras"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >

            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- COLUMNA IDCLIENTE -->
            <template #cell(nombreCliente)="data">
              <span
                v-if="data.item.nombreCliente"
              >
                {{ data.item.rutCliente }} - {{ data.item.nombreCliente }}
              </span>
              <span v-else>
                No registrado
              </span>
            </template>

            <!-- Column: fecha -->
            <template #cell(fecha)="data">
              {{ formatearFecha(data.item.fecha) }}
            </template>

            <!-- COLUMNA VALOR NETO -->
            <template #cell(valorNeto)="data">
              <div
                class="text-center"
              >
                {{ formatoPeso(data.item.valorTotal) }}
              </div>
            </template>

            <!-- COLUMNA VALOR TOTAL -->
            <template #cell(valorTotal)="data">
              <div
                class="text-center"
              >
                {{ formatoPeso(Math.round(data.item.valorTotal * 1.19)) }}
              </div>
            </template>

            <!-- Column: created_at -->
            <template #cell(created_at)="data">
              {{ formatearFechaHora(data.item.created_at) }}
            </template>

            <!-- Column: updated_at -->
            <template #cell(updated_at)="data">
              {{ formatearFechaHora(data.item.updated_at) }}
            </template>

            <!-- COLUMNA ESTADO -->
            <template
              #cell(estado)="data"
              class="text-center"
            >
              <colEstado
                :data="data"
                modulo="compras"
                :getUser="getUser"
                @processUpdateEstado="updateEstado"
              />
            </template>

            <!-- Column: Action -->
            <template #cell(acciones)="data">

              <colAccionesBtnes
                modulo="compras"
                :data="data"
                @PDFAction="PDFAction"
                @processGoToUpdate="goToUpdate"
                @processRemove="remove(data.item)"
              />
            </template>
          </b-table>
        </b-col>

        <b-col
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BCard, BOverlay,
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { avatarText } from '@core/utils/filter'
import { formatos } from '@/mixins/forms'
import store from '@/store/index'

const btnMostrar = () => import('@/layouts/components/Recycled/List/btnMostrar.vue')
const inputFiltro = () => import('@/layouts/components/Recycled/List/inputFiltro.vue')
const colAccionesBtnes = () => import('@/layouts/components/Recycled/List/colAccionesBtnesCompras.vue')
const btnCrear = () => import('@/layouts/components/Recycled/List/btnCrear.vue')
const colEstado = () => import('@/layouts/components/Recycled/List/colEstadoCompras.vue')
const spinner = () => import('@/layouts/components/Recycled/Form/spinner.vue')

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BCard,
    BOverlay,

    spinner,
    btnMostrar,
    inputFiltro,
    colAccionesBtnes,
    btnCrear,
    colEstado,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: true,
      spinner: false,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [3, 5, 10],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'correlativo',
          label: '#',
          sortable: true,
          thStyle: {
            width: '0px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'referencia',
          label: 'Referencia',
          sortable: true,
          thStyle: {
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha',
          label: 'Fecha',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '120px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'valorNeto',
          label: 'Valor Neto',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '50px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'valorTotal',
          label: 'Valor Total',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '50px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreProveedor',
          label: 'Proveedor',
          sortable: true,
          thStyle: {
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreUsuario',
          label: 'Creador',
          sortable: true,
          thStyle: {
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'created_at',
          label: 'Fecha Creación',
          sortable: true,
          tdClass: 'text-left',
          thStyle: {
            'text-align': 'left',
            width: '130px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'nombreUsuarioEdit',
          label: 'Última Edición',
          sortable: true,
          thStyle: {
            width: '150px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'updated_at',
          label: 'Fecha Edición',
          sortable: true,
          tdClass: 'text-left',
          thStyle: {
            'text-align': 'left',
            width: '130px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estado',
          label: 'Estado',
          sortable: true,
          tdClass: 'text-center',
          thStyle: {
            'text-align': 'center',
            width: '70px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getCompras: 'compras/getCompras',
      getUser: 'auth/user',
    }),
    // Vuexy
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  watch: {
    getCompras(val) {
      this.totalRows = val.length
      this.cargando = false
    },
  },
  mounted() {
    this.fetchCompras()
  },
  methods: {
    ...mapActions({
      fetchCompras: 'compras/fetchCompras',
      updateCompraEstado: 'compras/updateCompraEstado',
      removeCompras: 'compras/removeCompras',
      pdfCompra: 'compras/pdfCompra',
    }),
    ...mapMutations('compras', ['setCompra']),
    PDFAction(id, tipo) {
      const pdf = {
        id,
        tipo,
      }
      this.pdfCompra(pdf)
    },
    addCompra() {
      this.$router.replace({
        name: 'compras-create',
      })
    },
    updateEstado(compra) {
      this.$swal({
        title: 'Cambio de Estado!',
        text: `Estás seguro que deseas cambiar el estado de "${compra.correlativo}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, aprobar!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          this.updateCompraEstado(compra).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Estado actualizado!',
              text: `Se ha actualizado el estado de "${compra.correlativo}"!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },
    goToUpdate(compra) {
      this.setCompra(compra)
      this.$router.push({
        name: 'compras-update',
        params: { id: compra.id },
      })
    },
    remove(compra) {
      const referencia = compra.referencia != null ? `${compra.correlativo} - ${compra.referencia}` : compra.correlativo

      if (compra.estado === 'Pendiente') {
        this.$swal({
          title: 'Eliminar compra!',
          text: `Estás seguro que deseas eliminar la compra "${referencia}"?`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          this.spinner = true
          if (result.value) {
            const opciones = {
              id: compra.id,
            }
            this.removeCompras(opciones).then(() => {
              this.$swal({
                icon: 'success',
                title: 'Eliminada con éxito!',
                text: `"${referencia}" ha sido eliminada!`,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.spinner = false
            })
          } else {
            this.spinner = false
          }
        })
      } else if (compra.estado === 'Aprobado') {
        this.$swal({
          title: 'Eliminar compra!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, eliminalo!',
          cancelButtonText: 'Cancelar',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
          html: `
              <div style="margin-top:3%; text-align: center;">
                ¿Restablecer stock?
              </div>
              <div style="margin-top:2%; text-align: center;">
                <input type="radio" name="remove" value="siStock" checked/>
                <strong>SI</strong> &nbsp;
                <input type="radio" name="remove" value="noStock"/>
                <strong>NO</strong>
              </div>
              <br>

              ¿Estás seguro que deseas eliminar la compra "${referencia}"?
              <br><br>
            `,
        }).then(result => {
          this.spinner = true
          if (result.value) {
            const opcionStock = document.querySelector('input[name="remove"]:checked').value
            const opciones = {
              id: compra.id,
              opcionStock,
            }
            this.removeCompras(opciones).then(() => {
              const errorcompras = store.state.compras
              if (!errorcompras.error) {
                let text = `"${referencia}", `
                if (opcionStock === 'siStock') {
                  text += 'ha sido eliminada y su stock fue restablecido!'
                } else if (opcionStock === 'noStock') {
                  text += 'ha sido eliminada sin restablecer su stock!'
                }
                this.$swal({
                  icon: 'success',
                  title: 'Eliminada con éxito!',
                  text,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              } else if (errorcompras.error) {
                if (errorcompras.errorMessage === 'stock_negativo') {
                  this.$swal({
                    title: 'Error: La orden de compra no ha sido eliminada!',
                    text: 'Un producto de la orden de compra que desea eliminar, está quedando en stock negativo!.',
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                  })
                }
              }
              this.spinner = false
            })
          } else {
            this.spinner = false
          }
        })
      }
    },
    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
